import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { alertTriangleIcon, checkCircleIcon } from "@debionetwork/ui-icons";
import MenstrualCalendarBanner from "./Banner";
import { mapState } from "vuex";
import { generalDebounce } from "@/common/lib/utils";
import { getMenstrualSubscriptionPrices, getActiveSubscriptionByOwner, getMenstrualSubscriptionById, getMenstrualSubscriptionByOwner } from "@/common/lib/polkadot-provider/query/menstrual-subscription";
import { addMenstrualSubscriptionFee, addMenstrualSubscription, setMenstrualSubscriptionPaid } from "@/common/lib/polkadot-provider/command/menstrual-subscription";
import { formatPrice } from "@/common/lib/price-format";
import { getConversion } from "@/common/lib/api";
import Web3 from "web3";
export default {
  name: "SubscriptionSetting",
  components: {
    MenstrualCalendarBanner
  },
  data: () => ({
    alertTriangleIcon,
    checkCircleIcon,
    paymentPreview: false,
    isSuccess: false,
    showAlert: false,
    subscription: null,
    durationSubscription: {
      "Monthly": 30 * 24 * 60 * 60 * 1000,
      "Quarterly": 3 * 30 * 24 * 60 * 60 * 1000,
      "Yearly": 12 * 30 * 24 * 60 * 60 * 1000
    },
    reminder: "",
    subscriptionList: [],
    breadcrumbs: [{
      text: "Subscription Plan",
      disabled: false,
      href: ".subscription-setting__subscription-plan"
    }, {
      text: "Payment Preview",
      disabled: true,
      href: ".subscription-setting__plan-card"
    }],
    plans: [{
      label: "Monthly",
      duration: "Monthly",
      description: "For users on a budget who want to try out menstrual calendar",
      price: 0,
      currency: "DBIO",
      usd: 0,
      promo: "",
      periode: "Month",
      promoPrice: 0
    }, {
      label: "Quarterly",
      duration: "Quarterly",
      description: "Get full benefits on a discounted price",
      price: 0,
      currency: "DBIO",
      usd: 0,
      promo: "",
      periode: "3 Months",
      promoPrice: 0
    }, {
      label: "Annually",
      duration: "Yearly",
      description: "Get full benefits on a discounted price",
      price: 0,
      currency: "DBIO",
      usd: 0,
      promo: "",
      periode: "Year",
      promoPrice: 0
    }],
    loading: false,
    error: null
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      lastEventData: state => state.substrate.lastEventData,
      walletBalance: state => state.substrate.walletBalance
    })
  },
  watch: {
    lastEventData(e) {
      if (e !== null) {
        const dataEvent = JSON.parse(e.data.toString());
        if (dataEvent[1] === this.wallet.address) {
          if (e.method === "MenstrualSubscriptionAdded") {
            this.toPayment(dataEvent[0].id);
          }
          if (e.method === "MenstrualSubscriptionPaid") {
            this.showAlert = false;
            this.isSuccess = true;
            this.loading = false;
          }
        }
      }
    },
    subscription: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function () {
        await this.getTxWeight();
      }, 500)
    }
  },
  async created() {
    await this.getSubscriptionPrices();
    await this.getTxWeight();
    await this.getActiveSubscription();
    await this.getSubscriptonByOwner();
  },
  async mounted() {
    await this.getActiveSubscription();
    await this.getSubscriptonByOwner();
  },
  methods: {
    backButton() {
      this.$router.push({
        name: "menstrual-calendar-detail"
      });
    },
    async getSubscriptonByOwner() {
      const subscriptions = await getMenstrualSubscriptionByOwner(this.api, this.wallet.address);
      const subsIds = await Promise.all(subscriptions.map(subs => getMenstrualSubscriptionById(this.api, subs)));
      const subsPrices = await Promise.all(subsIds.map(subsId => getMenstrualSubscriptionPrices(this.api, subsId.duration, subsId.currency)));
      const rate = await this.getRate();
      let activeDate;
      this.subscriptionList = subsIds.filter(subsId => subsId.status !== "Inactive").map((subsId, index) => {
        const period = subsId.duration === "Monthly" ? "Month" : subsId.duration === "Quarterly" ? "3 Months" : "Year";
        const price = formatPrice(subsPrices[index].amount, subsId.currency);
        if (subsId.status === "Active") {
          activeDate = this.getExpiredDateSubsList(period, subsId.updatedAt);
        }
        const inQueueDate = subsId.status === "InQueue" ? this.getInQueueDate(period, activeDate ? activeDate.createdDate : subsId.updatedAt) : null;
        return {
          statusNumber: subsId.status === "Active" ? 1 : subsId.status === "InQueue" ? 2 : null,
          status: subsId.status,
          name: "Menstrual Date ".concat(subsId.duration),
          textStatus: subsId.status === "Active" ? "Active until ".concat(activeDate.day, " ").concat(activeDate.month, " ").concat(activeDate.year) : "Start on ".concat(activeDate.day + 1, " ").concat(activeDate.month, " ").concat(activeDate.year, " until ").concat(inQueueDate.day, " ").concat(inQueueDate.month, " ").concat(inQueueDate.year),
          price: price,
          usd: (parseFloat(price.split(",").join("")) * rate).toFixed(8)
        };
      });
    },
    getExpiredDateSubsList(period, startDate) {
      startDate = startDate.replace(/,/g, "");
      let timestamp = parseInt(startDate);
      const today = new Date(timestamp);
      let newDate;
      if (period === "Month") {
        newDate = new Date(today.setMonth(today.getMonth() + 1));
      }
      if (period === "3 Months") {
        newDate = new Date(today.setMonth(today.getMonth() + 3));
      }
      if (period === "Year") {
        newDate = new Date(today.setMonth(today.getMonth() + 12));
      }
      let day = newDate.getDate() - 1;
      let month = newDate.toLocaleString("default", {
        month: "long"
      });
      let year = newDate.getFullYear();
      return {
        day: day,
        month: month,
        year: year,
        createdDate: newDate
      };
    },
    getInQueueDate(period, startDate) {
      let timestamp = new Date(startDate).getTime();
      const today = new Date(timestamp);
      let newDate;
      let createdDate = today.toLocaleString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric"
      });
      if (period === "Month") {
        newDate = new Date(today.setMonth(today.getMonth() + 1));
      }
      if (period === "3 Months") {
        newDate = new Date(today.setMonth(today.getMonth() + 3));
      }
      if (period === "Year") {
        newDate = new Date(today.setMonth(today.getMonth() + 12));
      }
      let day = newDate.getDate();
      newDate.setDate(day);
      let month = newDate.toLocaleString("default", {
        month: "long"
      });
      let year = newDate.getFullYear();
      return {
        day: day,
        month: month,
        year: year,
        createdDate: createdDate
      };
    },
    getMonthNumber(month) {
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return months.indexOf(month);
    },
    async getActiveSubscription() {
      const activeSubs = await getActiveSubscriptionByOwner(this.api, this.wallet.address);
      const subscriptionDetail = await getMenstrualSubscriptionById(this.api, activeSubs);
      // get current date
      let currentDate = new Date().getTime();
      // date subscription
      const date = subscriptionDetail.updatedAt === "0" ? Number(subscriptionDetail.createdAt.split(",").join("")) : Number(subscriptionDetail.updatedAt.split(",").join(""));
      const endDateSubscription = date + this.durationSubscription[subscriptionDetail.duration];
      const reminder = Math.ceil((endDateSubscription - currentDate) / (24 * 60 * 60 * 1000));
      if (reminder <= 30) {
        this.reminder = reminder === 1 ? "".concat(reminder, " Day") : "".concat(reminder, " Days");
      } else if (reminder < 365) {
        const month = Math.floor(reminder / 30);
        const days = reminder % 30;
        if (days > 0) {
          this.reminder = "".concat(month, " ").concat(month === 1 ? "Month" : "Months", " ").concat(days, " ").concat(days === 1 ? "Day" : "Days");
        } else {
          this.reminder = "".concat(month, " ").concat(month === 1 ? "Month" : "Months");
        }
      } else {
        const year = Math.floor(reminder / 365);
        this.reminder = "".concat(year, " ").concat(year === 1 ? "Year" : "Years");
      }
    },
    async getRate() {
      const rate = await getConversion();
      return rate.dbioToUsd;
    },
    getExpiredDate(period) {
      const today = new Date();
      let newDate;
      if (period === "Month") {
        newDate = new Date(today.setMonth(today.getMonth() + 1));
      }
      if (period === "3 Months") {
        newDate = new Date(today.setMonth(today.getMonth() + 3));
      }
      if (period === "Year") {
        newDate = new Date(today.setMonth(today.getMonth() + 12));
      }
      let day = newDate.getDate() - 1;
      let month = newDate.toLocaleString("default", {
        month: "short"
      });
      let year = newDate.getFullYear();
      return "".concat(day, " ").concat(month, " ").concat(year);
    },
    async toSusbsribe() {
      this.loading = true;
      const price = Number(String(this.subscription.price).split(",").join(""));
      if (this.walletBalance < price) {
        this.error = {
          title: "Insufficient Balance",
          message: "Your transaction cannot go through because your account balance is too low or doesn't meet the minimum deposit needed. Please check your balance."
        };
        this.showAlert = false;
        this.loading = false;
        return;
      }
      await addMenstrualSubscription(this.api, this.wallet, this.subscription.duration, this.subscription.currency);
    },
    async toPayment(id) {
      await setMenstrualSubscriptionPaid(this.api, this.wallet, id);
    },
    async getSubscriptionPrices() {
      let monthlyPrice;
      this.plans.forEach(async plan => {
        getMenstrualSubscriptionPrices(this.api, plan.duration, plan.currency).then(data => {
          this.getRate().then(rate => {
            plan.price = formatPrice(data.amount, plan.currency);
            plan.usd = (Number(plan.price.split(",").join("")) * rate).toFixed(8);
            if (plan.duration === "Monthly") {
              monthlyPrice = plan.price;
            }
            if (plan.duration === "Quarterly") {
              plan.promoPrice = Number(monthlyPrice.split(",").join("")) * 3;
            }
            if (plan.duration === "Yearly") {
              plan.promoPrice = Number(monthlyPrice.split(",").join("")) * 12;
            }
          });
        });
      });
    },
    async getTxWeight() {
      var _this$subscription, _this$subscription2;
      const txWeight = await addMenstrualSubscriptionFee(this.api, this.wallet, (_this$subscription = this.subscription) === null || _this$subscription === void 0 ? void 0 : _this$subscription.duration, (_this$subscription2 = this.subscription) === null || _this$subscription2 === void 0 ? void 0 : _this$subscription2.currency);
      this.txWeight = "".concat(Number(Web3.utils.fromWei(String(txWeight.partialFee), "ether")).toFixed(8), " DBIO");
    },
    setActive(currency) {
      return currency === this.subscription.currency ? "secondary" : "";
    },
    toPaymentPreview() {
      this.paymentPreview = true;
      this.breadcrumbs[0].disabled = true;
      this.breadcrumbs[1].disabled = false;
    },
    toSubsPlan() {
      this.paymentPreview = false;
      this.breadcrumbs[0].disabled = false;
      this.breadcrumbs[1].disabled = true;
    },
    async paymentSuccessAction() {
      this.isSuccess = false;
      this.submitPreview = true;
      this.nextStatus = true;
      this.$router.push({
        name: "menstrual-calendar-detail"
      });
    },
    toMenstrualCalendar() {
      this.showAlert = false;
      this.isSuccess = false;
      this.$router.push({
        name: "menstrual-calendar-selection"
      });
    }
  }
};